import React from 'react';

import Layout from '../../components/layout';
import './index.sass';
import SEO from '../../components/seo';

const Index = () => {
  return (
      <Layout pageType="blog">
        <SEO
            keywords={['employee directory full reinstall']}
            title="Node Vision | Employee Directory - Full re-installation"
        />
        <div className="ed-change-log">
          <section className="heading">
            <h1>Employee Directory - Full re-installation</h1>
          </section>
          <section className="content">
            <div className="container">
              <div>In case, you can't add the app after installation (it's not showing up), follow the instructions below to fully uninstall the app and then install it again.<br/><br/></div>
              <div>
                <ol>
                  <li>Navigate to Site Content -> Find <strong>NODE VISION - Employee Directory</strong> -> Hover over the item -> Click the three dots to display a context menu (Return to Classic SharePoint if in the modern SharePoint) -> Click Remove (see <a href="https://cln.sh/cbRxae" target="_blank">here</a>) </li>
                  <li>Navigate to Site Settings -> Recycle Bin -> Remove the app from the bin (either Empty Recycle Bin or select the app and delete, see <a href="https://cln.sh/uVWJh7" target="_blank">here</a>) -> Click Second-stage recycle bin link and do the same for the second-stage bin. <strong>This step is important!</strong></li>
                  <li>Navigate to the App Catalog -> Remove the app (see <a href="https://cln.sh/iVzZpi" target="_blank">here</a>).</li>
                  <li>Install the app again.</li>
                </ol>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  );
};

export default Index;
